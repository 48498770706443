/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { useHeadless } from '../../context/headless-provider';

const selectMetatags = (context) => context.metatags;

function Seo({ lang, meta, title }) {
  const metatags = useHeadless(selectMetatags);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaAuthor = site.siteMetadata.author;
  const metaTitle =
    metatags
      .filter((t) => t.key === 'title')
      .reduce((_acc, t) => t.value, null) || site.siteMetadata?.title;
  const metaDescription = site.siteMetadata.description;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        titleTemplate={title ? `%s | ${title}` : null}
        meta={[
          // Noticed that Vimeo wasn't loading videos on Netlify subdomains even
          // though they were whitelisted in Vimeo. This seems to fix that by focing
          // the browser to set the Referer header to the origin for all https requests.
          {
            name: 'referrer',
            content: 'strict-origin',
          },
          {
            name: `author`,
            content: metaAuthor,
          },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:author`,
            content: metaAuthor,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
        ]
          .concat(meta)
          .concat(
            metatags
              .filter((t) => t.key !== 'title')
              .map((t) => ({ property: t.key, content: t.value })),
          )}
      />
    </>
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  metadata: {},
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default Seo;
