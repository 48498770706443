import React from 'react';

import { graphql } from 'gatsby';

import OrganicLeadPage from '../components/organic-lead/organic-lead-page';

export const query = graphql`
  query OrganicLeadPageQuery($pageId: String!, $breakpoints: [Int!]) {
    kalansoPage(pageId: { eq: $pageId }) {
      ...KalansoFullPageFragment
    }
  }
`;

const OrganicLeadTemplate = (props) => {
  return (
    <>
      <OrganicLeadPage {...props} />;
    </>
  );
};

export default OrganicLeadTemplate;
