import React, { useEffect, useMemo, useRef } from 'react';

import { useAuth } from '../../context/firebase-auth-provider';
import { useLearningHouse } from '../../context/learning-house-provider';
import {
  useFulcrumPageView,
  enhanceBankWithSessionDetails,
} from '../../context/fulcrum-provider';

export const FulcrumPageView = ({ page }) => {
  const fulcrum = useFulcrumPageView();
  const { user, synchronized } = useAuth();
  const { visitor } = useLearningHouse();

  const cachedPage = useRef(null);

  const session = useMemo(() => {
    if (synchronized) {
      return { user, visitor };
    }
  }, [synchronized, user, visitor]);

  useEffect(() => {
    if (!session) {
      return;
    }

    if (cachedPage.current === page.pageId) {
      console.log(`OOPS`);
      // We already evaluated this page
      return;
    }

    const bank = enhanceBankWithSessionDetails(
      {
        'asset.page.id': page.pageId,
        'asset.pack.id': page.packId,
        'asset.funnel.id': page.funnelId,
        'client.event.name': 'PageView',
        'client.window.location.href': window.location.href,
        'client.window.location.port': window.location.port,
        'client.window.location.protocol': window.location.protocol,
        'client.window.location.origin': window.location.origin,
        'client.window.location.host': window.location.host,
        'client.window.location.pathname': window.location.pathname,
        'client.window.location.search': window.location.search,
        'client.window.location.hash': window.location.hash,
      },
      session,
    );

    fulcrum.actions.logPageView(session, bank, page);

    cachedPage.current = page.pageId;
  }, [session, page]);

  return null;
};
