import React, { useState } from 'react';

import { Container, Box, Dialog, useMediaQuery, useTheme } from '@mui/material';

import Header from '../shared/header';
import Footer from '../shared/footer';
import Button from '../shared/button';
import Seo from '../shared/seo';

import LeadForm from '../lead-forms/lead-form';

import ClientOnly from '../shared/client-only';
import { FulcrumPageView } from '../fulcrum/fulcrum-page-view';

import { useHeadless } from '../../context/headless-provider';

import { Headline } from '../slots/headline';

const selectCallToActionAboveFold = (context, _variant) => {
  return context.inputs.find(
    (entity) => entity.role === 'call-to-action/above-fold',
  );
};

const OrganicLeadPage = (props) => {
  const { data } = props;
  const { kalansoPage } = data;

  const callToActionAboveFold = useHeadless(selectCallToActionAboveFold);

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => setIsModalOpen(false);

  return (
    <>
      <Seo />

      <Dialog
        maxWidth="xs"
        open={isModalOpen}
        onClose={handleClose}
        fullScreen={fullscreen}
      >
        <LeadForm
          name="organic-lead-form"
          page={kalansoPage}
          onClose={handleClose}
        />
      </Dialog>

      <Header
        page={kalansoPage}
        onClick={() => setIsModalOpen(true)}
        ctaButtonProps={{
          htmlData: {
            'data-click-location': `header`,
          },
          name: 'organic-lead-form-open-header',
        }}
      />

      <main>
        <Container
          maxWidth="md"
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              my: '1rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Headline />

            <br />
            <Button
              htmlData={{
                'data-click-location': `above-fold`,
              }}
              name="organic-lead-form-open-above-fold"
              variant="contained"
              size="large"
              onClick={() => setIsModalOpen(true)}
              page={kalansoPage}
            >
              {callToActionAboveFold.content.idleText}
            </Button>
          </Box>
        </Container>
      </main>

      <Footer page={kalansoPage} />

      <ClientOnly>
        <FulcrumPageView page={kalansoPage} />
      </ClientOnly>
    </>
  );
};

OrganicLeadPage.propTypes = {};

export default OrganicLeadPage;
