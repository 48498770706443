import React from 'react';
import PropTypes from 'prop-types';

import { Container, Box, useTheme } from '@mui/material';

import Link from './link';
import Button from './button';

import { useHeadless } from '../../context/headless-provider';

import { Left, Centered } from './logo';

const selectCallToActionInHeader = (context, variant) => {
  return context.inputs.find(
    (entity) => entity.role === 'call-to-action/header',
  );
};

const Header = ({ page, onClick, ctaButtonProps }) => {
  const theme = useTheme();

  const callToActionInHeader = useHeadless(selectCallToActionInHeader);

  return (
    <Box
      component="header"
      sx={{
        position: 'sticky',
        top: 0,
        transition: theme.transitions.create('top'),
        zIndex: theme.zIndex.appBar,
        boxShadow: `inset 0px -1px 1px ${theme.palette.grey[400]}`,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(20px)',
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: { xs: 'center', md: 'space-between' },
            alignItems: 'center',
            minHeight: '56px',
          }}
        >
          <Link
            to={'/'}
            color="secondary"
            htmlData={{ 'data-click-location': `page/${page.pageId}/logo` }}
            page={page}
          >
            <Box sx={{ display: { xs: 'inline', md: 'none' } }}>
              <Centered
                title="Successful Grower Secrets App"
                style={{ width: '10rem' }}
              />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'inline' } }}>
              <Left
                title="Successful Grower Secrets App"
                style={{ width: '10rem' }}
              />
            </Box>
          </Link>
          {callToActionInHeader && (
            <Button
              variant="contained"
              size="small"
              sx={{
                display: {
                  xs: 'none',
                  md: 'initial',
                },
              }}
              page={page}
              onClick={onClick}
              {...ctaButtonProps}
            >
              {callToActionInHeader.content.idleText}
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
};

Header.propTypes = {
  logoButtonProps: PropTypes.object,
  ctaButtonProps: PropTypes.object,
};

Header.defaultProps = {
  logoButtonProps: {},
  ctaButtonProps: {},
};

export default Header;
